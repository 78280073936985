html,
body {
  font-family: 'Montserrat', Helvetica, sans-serif;
}
#root {
  font-family: 'Montserrat', Helvetica, sans-serif;
}

iframe {
  border: none;
}

.ccp-container {
  /* display: none; */
  background-color: white;
  /* position: absolute; */
  /* top: 20px;
  left: 20px; */
  height: 520px;
  width: 320px;
}
